import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import { useHref } from 'react-router-dom';
import './Analyzer.css';
import fileUploacIcon from '../Assets/Images/ic-upload.png'
import { getConfig } from '../../services/configService';


export const Analyzer = () => {
    const [content, setContent] = useState('');
    const [apiUrl, setApiUrl] = useState<string>("");

    useEffect(() => {    
      const loadConfig = async () => {
        try {
          const config = await getConfig();
          setApiUrl(config.apiUrl);
        } catch (error) {
          console.error("Error loading config:", error);
        }
      };
  
      loadConfig();        
    }, []);
    
    const [formData, setFormData] = useState({
        firstName: '',
        lastName:'',
        companyName:'',
        email: '',
        selectedFile: null as File | null,
        isagree: false ,
        isunderstand:false,
    });
    const [errors, setErrors] = useState({
        firstName: '',
        lastName:'',
        companyName:'',
        email: '',
        file: '',
        isagree:'',
        isunderstand:'',
    });
    const [apiResponse, setApiResponse] = useState('');
    
    //#region File upload 
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
          const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
          if (!allowedTypes.includes(file.type)) {
            setErrors({
              ...errors,
              file: 'Only PDF, DOCX, and TXT file formats are supported',
            });
          } else {
            setFormData({
              ...formData,
              selectedFile: file,
            });
            setErrors({
              ...errors,
              file: '',
            });
          }
        }
    };
    //#endregion

    //#region Handle validaiton
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };
    //#endregion

     //#region Redirect Call
     //const href = useHref({ pathname: '/Contract' });
    
     //#endregion

     
            
    //#region Form submit
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {            
        console.log("First Name = " + formData.firstName);
 
            event.preventDefault();
            // Validate other fields if needed
            if (formData.firstName === '') {
              setErrors({
                ...errors,
                firstName: 'First Name is required',
              });
              return;
            }

            if (formData.lastName === '') {
              setErrors({
                ...errors,
                lastName: 'Last Name is required',
              });
              return;
            }
            if (formData.companyName === '') {
              setErrors({
                ...errors,
                companyName: 'Company Name is required',
              });
              return;
            }
            if (formData.email === '') {
              setErrors({
                ...errors,
                email: 'Email is required',
              });
              return;
            }
            else if (!/\S+@\S+\.\S+/.test(formData.email)) {
              setErrors({
                ...errors,
                email: 'Email is invalid',
              });      
              return;       
            }      
            if (!formData.selectedFile) {
              setErrors({
                ...errors,
                file: 'Please select a file',
              });
              return;
            }
            if (!formData.isagree) {
              setErrors({
                ...errors,
                isagree: 'You must agree to the terms',
              });
              return;
            }
            if (!formData.isunderstand) {
              setErrors({
                ...errors,
                isunderstand: 'You must agree and understand',
              });
              return;
            }           
              
            let userDetailID : any;
            //#region Create User API
            try {
              //const axios = require('axios');
              let userData = JSON.stringify({
                  "FirstName": formData.firstName,
                  "LastName": formData.lastName,
                  "Email": formData.email,
                  "CompanyName": formData.companyName
              });
                console.log('Call create user API:');
              let userConfig = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: `${apiUrl}/Kognova/CreateUserInfo`,
                  headers: {
                    'Content-Type': 'application/json'
                },
                  data: userData
               };
                console.log('Call API:', userConfig);
    
              const userResponse = await axios.request(userConfig)
                  .then((response: AxiosResponse<any>) => {                    
                      console.log('JSON DATA:',JSON.stringify(response.data));
                      userDetailID = response.data.response;
                      console.log('userDetailID:', userDetailID);
                      localStorage.setItem('userDetailID', userDetailID.userdetailsid);
                  })
                  .catch((error: AxiosResponse<any>) => {
                      console.log(error);
                  });
                console.log('create user API response:', userDetailID.userdetailsid);
    
            } catch (error) {
              console.error('Error:', error);
            }
            //#endregion
            // Handle form submission           
            // Reset form fields and errors after successful submission
            const responseFile = await fetch(`${process.env.PUBLIC_URL}/nda_model.json`); // Relative path to the text file
            if (!responseFile.ok) {
              throw new Error('Failed to fetch file');
            }
                                    
            const modelContent = await responseFile.text();         
            const formDataAPI = new FormData();
            formDataAPI.append('file', formData.selectedFile);
            formDataAPI.append('UserDetailID', userDetailID); 

            console.log('Form API DAta:', formDataAPI);
            try {
                let data = formDataAPI
                  let config = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: `${apiUrl}/Kognova/StartAnalysis`,                    
                      data: data
                  };

                console.log('Form API data1:', config.data);

                  const analysisResponse = await axios.request(config)
                      .then((response: AxiosResponse<any>) => {       
                                        
                          if (response.data.isSuccess) {
                              console.log('Form API data4:');
                              //window.location.href = href;
                              const operationId = response.data.response.operation_id;
                              localStorage.setItem('operationId', operationId);
                            alert(response.data.message);
                          }
                          else {
                              console.log('Form API data3:');
                            alert(response.data.message);
                            window.location.reload();
                           }                        
                      })
                      .catch((error: AxiosResponse<any>) => {
                          console.log(error);
                  });
      
              } catch (error) {
                  console.error('Error:', error);
              }             
    };

   
    //#endregion   

    return (
        <form onSubmit={handleSubmit}>   
        <h1 className="ubuntu-bold">NDA Analyzer</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. <a href="#">Learn more here.</a></p>        
        <div className="row mt-5">
              <div className="col-sm-6 legendform">
                <label htmlFor="exampleInputEmail1" className="form-label">First Name</label>
                <input type="text" className="form-control" name="firstName" value={formData.firstName} onChange={handleInputChange}/>               
                {errors.firstName && <div style={{ color: 'red' }}>{errors.firstName}</div>}
              </div>
              <div className="col-sm-6 legendform">
                <label htmlFor="exampleInputPassword1" className="form-label">Last Name</label>
                <input type="text" className="form-control" name="lastName" value={formData.lastName} onChange={handleInputChange}/>               
                {errors.lastName && <div style={{ color: 'red' }}>{errors.lastName}</div>}
              </div>
              <div className="col-sm-6 legendform">
                <label htmlFor="exampleInputEmail1" className="form-label">My Company (Subsidiary)</label>               
                <input type="text" className="form-control" name="companyName" value={formData.companyName} onChange={handleInputChange}/>               
                {errors.companyName && <div style={{ color: 'red' }}>{errors.companyName}</div>}
              </div>
              <div className="col-sm-6 legendform">
                <label htmlFor="exampleInputPassword1" className="form-label">Email</label>
                <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange}/>          
                {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
              </div>
         </div>    
          	
         <div className="image-upload mb-4">
              <label className="" htmlFor="inputGroupFile02"><img src={fileUploacIcon} alt="" /> Upload a File (PDF, .DOCX, or .TXT):</label>
              <input type="file" id="inputGroupFile02" accept=".pdf,.docx,.txt" onChange={handleFileChange}/>
              {errors.file && <div style={{ color: 'red' }}>{errors.file}</div>}
            </div>
           <div className="mb-2 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1" name="isagree" checked={formData.isagree} onChange={handleInputChange}/>
                <label className="form-check-label" htmlFor="exampleCheck1">I read and agree with <a href="#">Terms of Use</a></label>
                {errors.isagree && <div style={{ color: 'red' }}>{errors.isagree}</div>}
           </div> 
           <div className="mb-2 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck2" name="isunderstand" checked={formData.isunderstand} onChange={handleInputChange}/>
                <label className="form-check-label" htmlFor="exampleCheck2">I understand that the resulting analysis does not constitute legal advice.</label>
                {errors.isunderstand && <div style={{ color: 'red' }}>{errors.isunderstand}</div>}
           </div>    
         
        <div className="btnblock m-4 mt-5"><button type="submit" className="btn btn-primary btn-big" >ANALYZE</button></div>      
        </form>
     )
}
