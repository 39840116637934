import { useHref } from 'react-router-dom';
import newLogo from '../../components/Assets/Images/full-logo.png';
import './HalfReport.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getConfig } from '../../services/configService';

interface DealData {
    accountId: number;
    autoRenewMonths: number;
    company_name: string | null;
    completionStatus: number;
    contractId: string | null;
    contract_name: string;
    contractkenId: string | null;
    createdAt: string;
    createdById: number;
    dealId: string | null;
    description: string;
    effective_date: string;
    estimatedValue: number;
    estimatedValueCurrency: string;
    expiration_date: string;
    model_name: string;
    modelTypeId: number;
    final_draft_score: number;
    name_of_the_other_party: string;
}

export const HalfReport = () => {

    const href = useHref({ pathname: '/FullReport' });
    const [apiUrl, setApiUrl] = useState<string>("");
    const [stripeUrl, setstripeUrl] = useState<string>("");

    const [reportData, setReportData] = useState<DealData | null>(null);
    const dealId = 7;
    //const dealIdForHalfReport = localStorage.getItem('dealId');
    
    //const dealId = dealIdForHalfReport ? parseInt(dealIdForHalfReport, 10) : null;
 
    useEffect(() => {
        const loadConfig = async () => {
            try {
                const config = await getConfig();
                setApiUrl(config.apiUrl);
                setstripeUrl(config.stripePaymentUrl);
            } catch (error) {
                console.error("Error loading config:", error);
            }
        };

        loadConfig();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!dealId || !apiUrl) return;
                console.log('API Url:', apiUrl);
                const response = await axios.get<{ response: DealData[] }>(`${apiUrl}/MarkUp/GetStoredResponses`);
                console.log('response:', response.data);
                //const data = response.data.response;

                if (Array.isArray(response.data) && response.data.length > 0) {
                    const data = response.data[0]?.response;
                    console.log('Extracted Data:', data);
                    setReportData(data || null);
                    console.log('setReportData', data);
                } else {
                    console.error('Unexpected response structure:', response.data);
                    setReportData(null);
                }

                /*setReportData(data || null);*/
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dealId, apiUrl]);  

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const handleClick = () => {
        //window.location.href = href;
       window.open(`${stripeUrl}`, '_blank');
    };

    console.log("Path to logo:", newLogo);
    return (
        <section className="wrapper-inner">
            <header>
                <div className="container">
                    <div className="logo"><img src={newLogo} alt="" /></div>
                </div>
            </header>
            <section className="main-content">
                <div className="container">
                    <h1 className="light">{reportData?.contract_name} &nbsp;-&nbsp; {reportData?.company_name} &nbsp;-&nbsp; {reportData?.name_of_the_other_party}</h1>
                    <p><strong>Model:</strong> {reportData?.model_name}</p>

                    <div className="row capsule">
                        <div className="col-sm-6 capsule-left">
                            <p className="staticlbl"><span>Effective Date</span>{reportData ? formatDate(reportData.effective_date) : ''}</p>
                            <p className="staticlbl"><span>Expiration Date</span>{reportData ? formatDate(reportData.expiration_date) : ''}</p>
                        </div>
                        <div className="col-sm-6 capsule-right">
                            <p className="score">Current Deal Score<span>{reportData?.final_draft_score}</span></p>
                        </div>
                    </div>

                    <div className="btn-block">
                        <button type="button" className="btn btn-primary btn-big" onClick={handleClick}>Unlock Full Report $ XYZ</button>
                    </div>

                </div>
            </section>

        </section>
    )
}
